import Page from '../Page';
import gsap from 'gsap';
import Marquee3k from 'marquee3000';

class Artist extends Page {
    constructor() {
        super();
    }

    onEnter() {
        this.artistDetailsPanel = document.querySelector('.page__artist_artistDetailsPanel');
        this.background = document.querySelector('.backgroundSwipe');
        this.bio = document.querySelector('.bio-content');
        this.bioWrapper = document.querySelector('.bio-wrapper');
        this.artistImagePlane = document.querySelector('.artistImagePlane');
		this.artistImageContainer = document.querySelector('.artistImagePlane_inner');
        this.artistName = document.querySelector('.artist-label').innerHTML.split(' ');

        if (this.mobile.phone() || window.innerWidth < 640) {
            const x = (window.innerWidth / 2) - 190;
            const y = (window.innerHeight / 2) + 50;
            this.artistDetailsPanel.style.left = `${x}px`;
            this.artistDetailsPanel.style.top = `${y}px`;
        }

		this.makeDraggable(this.artistDetailsPanel);
        this.makeDraggable(this.artistImagePlane);

        this.openArtistImage = this.openArtistImage.bind(this);

        gsap.to(this.background, {
            width: '100%',
            duration: .3,
            onComplete: () => {
                this.loadContent()
            }
        });

        this.bioWrapper.addEventListener('touchmove', () => {
            this.scrollMouseDown = true;
        });

        this.bioWrapper.addEventListener('touchend', () => {
            this.scrollMouseDown = false;
        });
    }

    loadContent() {
        gsap.to(this.artistDetailsPanel, {
            opacity: 1,
            duration: 1,
            delay: 1,
            onComplete: () => {
                this.buildBackgroundScroller();
                const wrapper = document.querySelector('.scoller-artist');
                const content = document.querySelector('.bio-content');
                this.setupScrolling(wrapper, content, ".bio-wrapper");
                this.openArtistImage() 
            }
        });
    }

    openArtistImage() {
        const photo = this.artistImagePlane.dataset.photo;

        this.artistImageContainer.style.backgroundImage = `url(${photo})`;
        this.artistImagePlane.classList.add('in');
	}

    buildBackgroundScroller() {
        const topScroll = document.querySelector('.artistNameTop span');
        const bottomScroll = document.querySelector('.artistNameBottom span');

        topScroll.innerHTML = `${this.artistName[0]} &nbsp; `;
        bottomScroll.innerHTML = `${this.cleanName(this.artistName)} &nbsp;`;

        Marquee3k.init();
	}

    cleanName(name) {
        let parsedName = name[1] ? name[1] : name[0];

        if (!name[1]) {
            return parsedName;
        }

        if (name[1].length === 1) { 
            return parsedName = `${name[0]} ${name[1]}`;
        }

        if (name[2]) {
            parsedName = `${parsedName} ${name[2]}`;
        }

        if (name[3]) {
            parsedName = `${parsedName} ${name[3]}`;
        }

        if (name[4]) {
            parsedName = `${parsedName} ${name[4]}`;
        }

        return parsedName;
    }

};

export default Artist;