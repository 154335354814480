import {Vec2} from "curtainsjs";
import Animator from './Animator';
import Lenis from '@studio-freight/lenis'
import Draggable from 'draggable';
import MobileDetect from 'mobile-detect';
import barba from '@barba/core';
import { install } from "resize-observer";

class Page {
    constructor() {
        this.mobile = new MobileDetect(window.navigator.userAgent);
        this.mobileMenuItems = document.querySelectorAll('.mobile-menu-item');
        this.mobileButton = document.querySelector('.btn-mobile');
        this.mobileMenu = document.querySelector('.mobile-nav');
        this.animations = null;
        this.mouse = new Vec2();
        this.mousedown = false;
        this.scrollMouseDown = false;
        this.wipe = document.querySelector('.wipe');
        this.scrollHeight = 0;
        this.scrollThumb = document.getElementById('thumb');

        install();
        
        if (this.scrollThumb) {
            this.scrollThumb.addEventListener('mousedown', () => {
                this.scrollMouseDown = true;
            });
        }

        document.addEventListener('mouseup', () => {
            this.scrollMouseDown = false;
        });

        this.mobileButton.addEventListener('click', () => {
           this.mobileMenu.classList.add('active');
        });

        this.mobileMenuItems.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopImmediatePropagation();
                this.mobileMenu.classList.remove('active');
                barba.go(e.target.dataset.url);
            });
        });
    }

    setMouseDown() {
        this.scrollMouseDown = false;
    }

    loadAnimations() {
        this.animations = new Animator();
    }

    dragThumb() {
        var element = document.getElementById('thumb');
    
        var options = {
            limit: {
                x: [-9,-9],
                y: [0,this.scrollHeight - 18 ]
            },
            onDrag: (e, x, y) => { 
                this.lenisScroll.scrollTo(y, { immediate: true });
            }
        };
        new Draggable (element, options);
    }

    makeDraggable (elmnt, yOnly = false, xOnly = false, callback=null) {
       
       // if  (this.mobile.phone() || window.innerWidth < 600) return;
        let currentPosX = 0, currentPosY = 0, previousPosX = 0, previousPosY = 0;
        elmnt.onmousedown = dragMouseDown;
        elmnt.ontouchstart = dragMouseDown;
        const _self = this;
    
        function dragMouseDown (e) {
            if (e.target.classList.contains('btn-enquire')) return;
            if (_self.scrollMouseDown) return;
            e.preventDefault();
            
            previousPosX = e.clientX;
            previousPosY = e.clientY;
            
            document.onmouseup = closeDragElement;
            document.onmousemove = elementDrag;
            document.ontouchend = closeDragElement;
            document.ontouchmove = elementDrag;
        }
    
        function elementDrag (e) {

            if (_self.scrollMouseDown) return;
            e.preventDefault();

            const posX = e.clientX || e.touches[0].clientX;
            const posY = e.clientY || e.touches[0].clientY;

            currentPosX = previousPosX - posX;
            currentPosY = previousPosY - posY;
            previousPosX = posX;
            previousPosY = posY;
            
            if (yOnly) {
                elmnt.style.top = (elmnt.offsetTop - currentPosY) + 'px';
            } else if (xOnly) {
                elmnt.style.left = (elmnt.offsetLeft - currentPosX) + 'px';
            } else {
                elmnt.style.top = (elmnt.offsetTop - currentPosY) + 'px';
                elmnt.style.left = (elmnt.offsetLeft - currentPosX) + 'px';
            }

            if (callback) callback(e);
        }
    
        function closeDragElement () {
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    setupScrolling(wrapper, content, el, callback) {
        this.scrollbar = document.querySelector(".scrollbar");
        this.scrollContainer = document.querySelector(el);
        this.scrollContainerHeight = this.scrollContainer.getBoundingClientRect().height;
        this.scrollHeight = this.scrollContainerHeight;
        this.scrollContentHeight = content.getBoundingClientRect().height;
        this.scrollThumb = document.getElementById("thumb");
        this.scrollContent = content;
        this.scrollThumbHeight = this.scrollThumb.getBoundingClientRect().height;

        if (this.scrollContentHeight > this.scrollContainerHeight) {

            this.scrollbar.classList.add("scrollable");

            const lenis = new Lenis({
                wrapper: wrapper,
                content: this.scrollContent,
                smoothWheel: true,
                smoothTouch: true,
            });

            lenis.on("scroll", ({ scroll, limit, velocity, direction, progress }) => {
                if (!this.scrollMouseDown) {
                    this.scrollThumb.style.transform = `translate3d(0,${progress * (this.scrollContainerHeight - this.scrollThumbHeight)}px,0)`;
                }
            });

            this.lenisScroll = lenis;

            function raf(time) {
                lenis.raf(time)
                if (callback) callback(time);
                requestAnimationFrame(raf)
            }

            requestAnimationFrame(raf)
        } 

        if (!this.mobile.phone()) {
            // this.dragThumb();
        }
    }

}

export default Page;