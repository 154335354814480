import Page from '../Page';
import BackgroundImage from '../backgroundImage/BackgroundImage';
import vertex from '../../shaders/vertex.glsl';
import fragment from '../../shaders/fragment.glsl';
import barba from '@barba/core';

class Artists extends Page {
    constructor() {
        super();
        this.artistBoxes = document.querySelectorAll('.page__artists__panel_box a');
        this.bioWrapper = document.querySelector('.bio-wrapper');

        setTimeout(() => {
            const wrapper = document.querySelector('.scroller-artists');
            const content = document.querySelector('.panel_inner');
            this.setupScrolling(wrapper, content, ".page__artists__panel");

        }, 1000);

        this.setupListeners();
    }

    onEnter() {
        this.background = new BackgroundImage({
            vertex,
            fragment
        });

        if( this.bioWrapper){
            this.bioWrapper.addEventListener('touchmove', () => {
                this.scrollMouseDown = true;
            });

            this.bioWrapper.addEventListener('touchend', () => {
                this.scrollMouseDown = false;
            });
        }
    }

    setupListeners() {
        this.artistBoxes.forEach(artist => {
            if (this.mobile.phone()) return;
            artist.addEventListener('touchstart', this.swapImage.bind(this));
            artist.addEventListener('mouseover', this.swapImage.bind(this));
        });
    }

    openArtist(url) {
        setTimeout(()=>{
            barba.go(url);
        }, 700);
    }

    swapImage(e){
        e.preventDefault();
        this.background.swapImage(e.currentTarget.dataset.image);
        const url = e.currentTarget.dataset.url
        if (this.mobile.tablet()) {
            setTimeout(() => {
                this.openArtist(url);
            }, 1000);
        }
    }

    cleanup() {
        this.artistBoxes.forEach(artist => {
            artist.removeEventListener('mouseover', this.swapImage);
        });
        this.background.destroy();
    }
}

export default Artists;