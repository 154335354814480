class ParseCSS {
    constructor(args) {
        this.element = args.element;
        this.padding = args.padding || 0;
    }

    parseButtons() {
        const containerDimensions = this.element.getBoundingClientRect();
        const outerWrapper = document.createElement('span');
        const innerWrapper = document.createElement('span');
        const label = document.createElement('label');
        const labelHover = document.createElement('label');
        const value = this.element.innerHTML;
        this.element.innerHTML = null;

        label.append(value)
        labelHover.append(value);
        
        innerWrapper.append(labelHover);
        innerWrapper.append(label);
        outerWrapper.append(innerWrapper)
        this.element.append(outerWrapper);

        label.style = `display:block; height: ${containerDimensions.height}px; line-height: ${containerDimensions.height}px`
        labelHover.style = `display:block; height: ${containerDimensions.height}px; line-height: ${containerDimensions.height}px`
        innerWrapper.style = `display:block;` 
        this.element.style = `overflow:hidden; cursor: pointer; height:${containerDimensions.height}px`;

        let duration, delay, direction, ease;

        this.element.classList.forEach(name => {
            if (name.includes('duration-')) {
                duration = parseFloat(name.replace('duration-', ''));
            }

            if (name.includes('delay-')) {
                delay = parseFloat(name.replace('delay-', ''));
            }

            if (name.includes('direction-')) {
                direction = name.replace('direction-', '');
            }

            if (name.includes('ease-')) {
                ease = name.replace('ease-', '');
            }
        });

        return {
            element: this.element,
            duration,
            direction,
            delay,
            ease,
            width: containerDimensions.width,
            height: containerDimensions.height,           
            x: containerDimensions.x,
            y: containerDimensions.y
        }
    }

    parseTypeOut() {
        let duration, direction, delay, ease;
        const outerWrapper = document.createElement('span');
        const innerWrapper = document.createElement('span');
        const cursor = document.createElement('span');
        const parts = this.element.innerHTML.replace(/(<([^>]+)>)/gi, "").split('');
        
        cursor.classList.add('flashing-cursor');
        this.element.innerHTML = null;
        cursor.innerHTML = ' ';

        parts.forEach(letter => {
            const span = document.createElement('span');
            span.innerHTML = letter;
            innerWrapper.append(span);
        });

        outerWrapper.append(innerWrapper);
        outerWrapper.append(cursor)
        this.element.append(outerWrapper);

        // check if speed / direction set --
        this.element.classList.forEach(name => {

            if (name.includes('duration-')) {
                duration = parseFloat(name.replace('duration-', ''));
            }

            if (name.includes('delay-')) {
                delay = parseFloat(name.replace('delay-', ''));
            }

            if (name.includes('direction-')) {
                direction = name.replace('direction-', '');
            }

            if (name.includes('ease-')) {
                ease = name.replace('ease-', '');
            }

            // if (name.includes('animate-')) {
            //     flow = name.replace('animate-', '');
            // }
        });

        const containerDimensions = this.element.getBoundingClientRect();
        const row = this.element.children[0].getBoundingClientRect();

        return {
            element: this.element,
            duration,
            direction,
            delay,
            ease,
            width: containerDimensions.width,
            height: containerDimensions.height,
            rowHeight: row.height,
            x: containerDimensions.x,
            y: containerDimensions.y
        }
    }

    parseHeadline() {
        let duration, direction, delay, ease;
        
        // split words --- 
        const parts = this.element.innerHTML.split(' ');
        this.element.innerHTML = null;
        parts.forEach(word => {
            const div = document.createElement('div');
            const outerSpan = document.createElement('span');
            const span = document.createElement('span');
            
            span.innerHTML = word;
            outerSpan.append(span);
            div.append(outerSpan);
            this.element.append(div);
        });

        // check if speed / direction set --
        this.element.classList.forEach(name => {
            if (name.includes('duration-')) {
                duration = parseFloat(name.replace('duration-', ''));
            }

            if (name.includes('delay-')) {
                delay = parseFloat(name.replace('delay-', ''));
            }

            if (name.includes('direction-')) {
                direction = name.replace('direction-', '');
            }

            if (name.includes('ease-')) {
                ease = name.replace('ease-', '');
            }

            // if (name.includes('animate-')) {
            //     flow = name.replace('animate-', '');
            // }
        });

        const containerDimensions = this.element.getBoundingClientRect();
        const row = this.element.children[0].getBoundingClientRect();

        this.element.style.width = `${containerDimensions.width + this.padding}px`;

        return {
            element: this.element,
            duration,
            direction,
            delay,
            ease,
            width: containerDimensions.width,
            height: containerDimensions.height,
            rowHeight: row.height,
            x: containerDimensions.x,
            y: containerDimensions.y
        }
    }
}

export default ParseCSS;