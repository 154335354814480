
import gsap from "gsap";
import ParseCSS from './ParseCSS';

class Button {
    constructor(args) {
        this.button = args.button || null;
        this.elements = [];
        this.timeline = new gsap.timeline({ onComplete: () => this.isAnimating = false });
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.isAnimating = false;
        this.mouseFollower = document.querySelector('.mousefollower');
        this.setup();
    }

    setup() {
        const el = new ParseCSS({
            element: this.button
        }).parseButtons();

        this.setupTimelines(el);

    }

    setupTimelines(el) {
        const innerSpan = el.element.children[0].children[0];
        
        this.timeline.fromTo(innerSpan,
            {
                translate: `0% -50%`,
                duration: 0.2,
                delay: 0,
                ease: 'linear'
            },
            {
                translate: `0% 0%`,
                duration: 0.2,
                delay: 0,
                ease: 'linear'
            }
        ).pause();

        this.button.addEventListener('mouseenter', ()=>this.handleMouseOver(el));
        this.button.addEventListener('mouseleave', ()=>this.handleMouseOut(el));

        this.button.classList.add('show');
    }

    handleMouseOver() {
        this.mouseFollower.classList.add('hover');
        this.timeline.play();
    }

    handleMouseOut() {
        this.mouseFollower.classList.remove('hover');
        this.timeline.reverse();
    }
}

export default Button;