
class RandomType {
    constructor(args) {
        this.range = 'abcdefghijklmnopqrstuvwxyz1234567890/#!_';
        this.type = args.type || '';
        this.characters = [];
        this.matrix = []
        this.split()
    }

    split() {
        
        const parts = this.type.innerHTML.replace(/(<([^>]+)>)/gi, "").split('');
        
        this.type.innerHTML = null;
        
        parts.forEach(letter => {
            const span = document.createElement('span');
            span.innerHTML = letter;
            this.type.append(span);
            this.characters.push(span);
        });

        this.type.classList.add('show');

        setInterval(() => {
            const rand1 = Math.floor(Math.random() * ((this.characters.length) - 0 + 0) + 0);
            const rand2 = Math.floor(Math.random() * ((this.range.length) - 0 + 0) + 0);
            this.characters[rand1].innerHTML = this.range[rand2]
        }, 20);
       
    }
}

export default RandomType;

