import Page from '../Page';
import gsap from 'gsap';
import Marquee3k from 'marquee3000';

class Contact extends Page {
    constructor() {
        super();
    }

    onEnter() {
        this.artistDetailsPanel = document.querySelector('.page__contact_contactDetailsPanel');
        this.background = document.querySelector('.backgroundSwipe');
        this.bio = document.querySelector('.bio-content');
        this.artistImagePlane = document.querySelector('.contactImagePlane');
		this.artistImageContainer = document.querySelector('.contactImagePlane_inner');
        this.artistName = document.querySelector('.contact-label').innerHTML.split(' ');

        if (this.mobile.phone() || window.innerWidth < 640) {
            const x = (window.innerWidth / 2) - 190;
            const y = (window.innerHeight / 2) + 50;
            this.artistDetailsPanel.style.left = `${x}px`;
            this.artistDetailsPanel.style.top = `${y}px`;
        }

		if (!this.mobile.phone() || !window.innerWidth < 640) {
            this.makeDraggable(this.artistDetailsPanel);
        }
        this.makeDraggable(this.artistImagePlane);
        this.buildBackgroundScroller();
        this.openArtistImage = this.openArtistImage.bind(this);

        gsap.to(this.background, {
            width: '100%',
            duration: .3,
            onComplete: () => {
                this.loadContent()
            }
        });
    }

    loadContent() {
        gsap.to(this.artistDetailsPanel, {
            opacity: 1,
            duration: 1,
            delay: 1,
            onComplete: () => {
                this.openArtistImage() 
            }
        });
    }

    openArtistImage() {
        this.artistImagePlane.classList.add('in');
	}

    buildBackgroundScroller() {
		Marquee3k.init();
	}

};

export default Contact;