import Headline from './animator/Headline';
import TypeOut from './animator/TypeOut';
import Button from './animator/Button';
import RandomType from './animator/RandomType';

class Animator {
    constructor() {
        this.setup();
    }

    setup() {
        //animate random type
        const randomType = document.querySelectorAll('.randomType');

        randomType.forEach(type => {
            new RandomType({type});
        })

        //animate headlines
        const headlines = document.querySelectorAll('.headline');
        new Headline({ headlines });

        //animate typeout
        const typeouts = document.querySelectorAll('.typeout');
        new TypeOut({ typeouts });

        //animate buttons
        const buttons = document.querySelectorAll('.button');
        
        buttons.forEach(button => {
            new Button({ button });
        });
    }

    unload() {

    }
}

export default Animator;