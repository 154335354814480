import barba from '@barba/core';
import Loading from './pages/Loading';
import Home from './pages/Home';
import Artists from './pages/Artists';
import Artist from './pages/Artist';
import Contact from './pages/Contact';
import gsap from 'gsap';

class App {
    constructor() {
      this.body = document.querySelector('body');
      this.page = null;
      this.loading = true;
      this.activePage = this.loading ? 'loading' : this.body.dataset.page;
      this.wipe = document.querySelector('.wipe');
  
      this.pageMapping = {
        Loading,
        Home,
        Contact,
        Artists,
        Artist,
      } 
      
      this.cancelLoading = this.cancelLoading.bind(this);
      this.setupListeners = this.setupListeners.bind(this);
      this.listeners = this.setupListeners();

      this.setupRouting();
    }
  
    createPage(controller, params) {
        const newPage = this.loading ? 'loading' : controller;
        const className = newPage.charAt(0).toUpperCase() + newPage.slice(1);

        try {
          this.page = new this.pageMapping[className]({
            cancelLoading: this.cancelLoading,
            name: newPage, 
            canvas: this.canvas, 
            request: controller,
            params
          });
        } catch (error) {
          // console.log(error);
        }
    }

    cancelLoading(controller, params) {

      let path = '/';

      if (controller !== null) {
        path = `/${controller}`;
      }

      if (params !== null) {
        path = `/${controller}/${params}`;
      }

      if (controller === 'home') {
        path = '/';
      }

      this.activePage = controller;
      this.loading = false;
      barba.go(path)
    }
  
    setupRouting() {
      const _self = this;

      barba.init({
        schema: {
            prefix: 'data-application',
            wrapper: 'container'
        },
        transitions: [{
            name: 'default-transition',
            leave(data) {
              return gsap.to('.page', {
                  opacity: 0,
                  duration:0.3,
                  onComplete: () => {
                    if (_self.page && _self.page.cleanup) _self.page.cleanup();
                    gsap.to('.page', {
                      opacity: 1,
                      delay: 0,
                      duration:0.3,
                      onComplete: () => {
                        if (_self.page && _self.page.onEnter) _self.page.onEnter();
                      }
                    });
                  }
              })
            },
        }],
        views: this.listeners
      });
    }
  
    setupListeners() {
      const _self = this;
      const listen = [];
      
      const request = window.location.pathname.split('/');
      let controller = request[1] || null;
      let params = request[2] || null;

      if (controller === null) {
        controller = 'home';
      }

      for (const [key] of Object.entries(this.pageMapping)) {
        listen.push ({
          namespace: key.toLowerCase(),
          beforeEnter() {
            _self.createPage(key.toLowerCase(), params);
          }
        })
      }

      return listen;
    }
  }
  
export default App;