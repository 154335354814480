import axios from 'axios';
import Animator from '../Animator';
import lottie from 'lottie-web';

class Loading  {
    constructor(args) {
        const { request, cancelLoading, params } = args;
        this.body = document.querySelector('body');
        this.request = request;
        this.params = params
        this.getLoadingPage(cancelLoading)
    }

    getLoadingPage(cancelLoading) {
        axios.get('/loading').then(res => {
            const content = res.data;
            let parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');

            const loadingContent = doc.querySelector('.page__loading');
            const insert = document.querySelector('main');

            insert.innerHTML = null;
            insert.append(loadingContent)

            lottie.loadAnimation({
                container: document.querySelector('.preloader'), 
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/static/preloader.json'
            });
            
            lottie.setSpeed(1.4); 

            new Animator();

            // mock loading
            setTimeout(() => {
                this.body.classList.remove('loading');
                cancelLoading(this.request, this.params);
            }, 3000);
        })
        
    }
}

export default Loading;