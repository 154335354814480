import Page from '../Page';
import VanillaTilt from 'vanilla-tilt';
import Marquee3k from 'marquee3000';
import barba from '@barba/core';
    
class Home extends Page {
    constructor() {
        super();
		this.setupTilt();
		this.dragging = false;
		this.mousedown = false;
		this.artistURL = '';
		this.setupMarquee();
    }

	onEnter() {
		this.artistImagePlane = document.querySelector('.artistImagePlane');
		this.artistImageContainer = document.querySelector('.artistImagePlane_inner');

		this.makeDraggable(this.artistImagePlane);
		
		this.artistImagePlane.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopImmediatePropagation();
			if (!this.dragging) {
				this.artistImagePlane.classList.remove('in');
				barba.go(this.artistURL);
			}
		});

		this.artistImagePlane.addEventListener('touchend', (e) => {
			e.preventDefault();
			e.stopImmediatePropagation()

			setTimeout(()=>{
				this.dragging = false;
			}, 300);


			if (!this.dragging) {
				this.artistImagePlane.classList.remove('in');
				barba.go(this.artistURL);
			}
		});

		this.artistImagePlane.addEventListener('mousemove', (e) => {
			if (this.mousedown) {
				this.dragging = true;
			} else {
				this.dragging = false;
			}
		});

		this.artistImagePlane.addEventListener('mousedown', (e) => {
			this.mousedown = true;
		});

		this.artistImagePlane.addEventListener("mouseup", (event) => {
			this.mousedown = false;
		});

		this.artistImagePlane.addEventListener('touchmove', (e) => {
			this.dragging = true;
		});

		this.artistImagePlane.addEventListener('touchstart', (e) => {
			this.mousedown = true;
		});
	}

	setupTilt() {
        VanillaTilt.init(document.querySelector(".artist-scroll-wrapper"), {
            max: 8,
			perspective: 2000,
			reset: false,
            "full-page-listening": true,
			gyroscope: false
        });
    }

	setupMarquee() {
		Marquee3k.init();

		const links = document.querySelectorAll('.artist-scroll a');

		links.forEach((link) => {
			link.addEventListener('click', (e) => {
				e.preventDefault();
				e.stopImmediatePropagation();
				this.openArtistImage(e.currentTarget.dataset.url, e.currentTarget.dataset.image, e.clientX, e.clientY);
			});
		});
	}

	openArtistImage(url, photo, x, y) {
		setTimeout(() => {
			this.artistImageContainer.style.backgroundImage = `url(${photo})`;
		},300);

		this.artistURL = url;
		const setPosition = () => {
			if (y > window.innerHeight / 2) {
				y = y /2;
			}

			if (x > window.innerWidth / 2) {
				x = x /2;
			}

			if (this.mobile.phone() || this.mobile.tablet()) {
				x = (window.innerWidth / 2) - 190;
				y = (window.innerHeight / 2) - 190;
			}

			this.artistImagePlane.style.left = `${x}px`;
			this.artistImagePlane.style.top = `${y}px`;
		}
		
		const isOpen = this.artistImagePlane.classList.contains('in');

		if (isOpen) {
			this.artistImagePlane.classList.remove('in');
			setTimeout(()=>{
				setPosition();
				this.artistImagePlane.classList.add('in');
			}, 500);
			return;
		}

		setPosition();
		this.artistImagePlane.classList.add('in');
	}

	handleMovement() {
        let coords = this.artistPhotoPanel.mouseToPlaneCoords(this.mouse);
        if(this.mousedown) {
            this.artistPhotoPanel.relativeTranslation.x =  500 / 2 * coords.x;
            this.artistPhotoPanel.relativeTranslation.y =  500 / 2 * -coords.y;
        }
    }

	cleanup() {
		this.artistImagePlane.classList.remove('in');
		this.artistImageContainer.style.backgroundImage = '';
	}
}

export default Home;