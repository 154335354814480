import gsap from "gsap";
import ParseCSS from './ParseCSS';

class Headline {

    constructor(args) {
        this.headlines = args.headlines || null;
        this.width = 0;
        this.height = 0;
        this.speed = .3;
        this.duration = 1;
        this.padding = 3;
        this.direction = 'up';
        this.ease = 'expo.out';
        this.elements = [];
        this.flow = 'in';

        // observer for classname changes
        this.observer = new MutationObserver((mutationList) => {
            mutationList.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    this.update(mutation.target);
                }   
            })
        });

        this.setup();
    }

    setup() {
        this.headlines.forEach(element => {
            this.elements.push(new ParseCSS({
                element, 
                padding: this.padding
            }).parseHeadline()); 

            this.observer.observe(element, { attributes: true });
        });

        this.animate()
    }

    setPositions(object) {
        let translatePosition = [];
        if (object.direction === 'up') {
            translatePosition = [`0px`, `${object.rowHeight + this.padding}px`];
        } else if (object.direction === 'right') {
            translatePosition = [`${object.width + this.padding}px`, '0px']
        } else if (object.direction === 'down') {
            translatePosition = [`0px`, `${-object.rowHeight - this.padding}px`];
        } else if (object.direction === 'left') {
            translatePosition = [`${-object.width - this.padding}px`, '0px']
        } 

        return translatePosition;
    }

    animate(direction='in') {

        this.elements.forEach(object => {
            // unhide dom element
            if (!object.element.classList.contains('show')) {
                object.element.classList.add('show');
            }

            // set transform positions
            const translatePosition = this.setPositions(object)

            // animate inner spans
            const children = [...object.element.children];
            
            children.forEach((row,i) => {
                
                const aniPropsA = {
                    translate: `${translatePosition[0]} ${translatePosition[1]}`,
                    duration: object.duration,
                    delay: object.delay * i
                };
    
                const aniPropsB = {
                    delay: object.delay * i,
                    translate: `0px 0px`,
                    duration: object.duration,
                    ease: object.ease
                }

                const span = row.children[0].children[0];
                          
                gsap.fromTo(span,
                    direction==='in' ? aniPropsA : aniPropsB,
                    direction==='in' ? aniPropsB : aniPropsA
                );
            });         
        })
    }

    update(target) {
        if (target.classList.contains('animate-in')) {
            this.animate()
        } else if (target.classList.contains('animate-out')) {
            this.animate('out')
        }
    }
}

export default Headline;