import gsap from "gsap";
import ParseCSS from './ParseCSS';

class TypeOut {
    constructor(args) {
        this.typeouts = args.typeouts || null;
        this.elements = [];
        
        this.setup();
    }

    setup() {
        this.typeouts.forEach(element => {
            this.elements.push(new ParseCSS({
                element
            }).parseTypeOut()); 
        });

        this.animate()
    }

    animate(direction='in') {
        this.elements.forEach(object => {
            // unhide dom element
            if (!object.element.classList.contains('show')) {
                object.element.classList.add('show');
            }

            // animate inner spans
            const children = [...object.element.children];
            const letters = Array.prototype.slice.call(children[0].children[0].children)

            letters.forEach((row,i) => {
                
                const aniPropsA = {
                    display: 'none',
                    duration: object.duration,
                    delay: object.delay * i / 10
                };
    
                const aniPropsB = {
                    delay: object.delay * i / 10,
                    display: 'inline',
                    duration: object.duration,
                    ease: object.ease
                }

                gsap.fromTo(row,
                    direction==='in' ? aniPropsA : aniPropsB,
                    direction==='in' ? aniPropsB : aniPropsA
                );
            });         
        })
    }
}   

export default TypeOut;