import * as THREE from 'three';
import Stats from 'stats.js';

class BackgroundImage {
	constructor(args) {
        args = args || {};

        this.fragment = args.fragment || null;
        this.vertex = args.vertex || null;

		this.clock = new THREE.Clock();
		this.container = document.querySelector('#webgl');
		this.width = this.container.offsetWidth;
		this.height = this.container.offsetHeight;

		this.camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -1000, 1000 );
		this.camera.position.z = 2;
		//this.camera.fov = 2 * Math.atan( (this.height / 2) / 600) * 180/Math.PI;

		this.mouse = { x:0, y:0, prevX:0, prevY:0, vx:0, vy:0}
		this.scene = new THREE.Scene();
		this.renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } );
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize( this.width, this.height );
		this.container.appendChild( this.renderer.domElement );
		
		this.cameraDirection = new THREE.Vector3();
		this.camPositionSpan = document.querySelector("#position");
  		this.camLookAtSpan = document.querySelector("#lookingAt");

		this.camPositionSpan = document.querySelector("#position");
  		this.camLookAtSpan = document.querySelector("#lookingAt");

		this.renderQueue = [];
		this.settings = {};

		this.imageAspect = 1.7777;
		this.loadedImage = new THREE.TextureLoader().load('https://freemandigital.ams3.digitaloceanspaces.com/analog/alejo_7ca6a7eee9.jpg');
		this.activeImage = 0;

		this.stats = new Stats();
        this.stats.showPanel( 0 );

        this.resize = this.resize.bind(this);
		this.dataTextureSize = 16;

        //document.body.appendChild( this.stats.dom );

		this.swapImage = this.swapImage.bind(this);
		
		this.dataTexture();
		this.createPlane();
		this.setupResize();
		this.mouseEvents();
		this.resize();
		this.render();
	}

	destroy() {
		this.container.removeChild(this.renderer.domElement);
	}

	dataTexture() {
		const width = this.dataTextureSize;
		const height = this.dataTextureSize;

		const size = width * height;
		const data = new Float32Array( 3 * size );
		//const color = new THREE.Color( 0xffffff );

		// const r = Math.floor( color.r * 255 );
		// const g = Math.floor( color.g * 255 );
		// const b = Math.floor( color.b * 255 );

		for ( let i = 0; i < size; i ++ ) {
			let r = Math.random() * 5;

			const stride = i * 3;
			
			data[ stride ] = 0;
			data[ stride + 1 ] = r;
			data[ stride + 2 ] = 0;
		}
		
		// used the buffer to create a DataTexture
		this.texture = new THREE.DataTexture( data, width, height, THREE.RGBFormat, THREE.FloatType );
		this.texture.magFilter = this.texture.minFilter = THREE.NearestFilter;
	}

	updateDataTexture() {
		let data = this.texture.image.data;
		for (let i = 0; i < data.length; i+=3) {
			data[i] *= 0.9;
			data[i+1] *= 0.9;
		}

		let gridMouseX = this.dataTextureSize * this.mouse.x;
		let gridMouseY = this.dataTextureSize * (1-this.mouse.y);
		let maxDistance = 1;

		for (let i = 0; i< this.dataTextureSize; i++) {
			for (let j = 0; j < this.dataTextureSize; j++) {
				let distance = (gridMouseX - i) * (gridMouseX - i) + (gridMouseY - j) * (gridMouseY - j);
				let maxDistanceSq = maxDistance * maxDistance;

				if (distance < maxDistanceSq) {
					let power = maxDistance / Math.sqrt(distance);
					let index = 3 * (i + this.dataTextureSize * j);
					data[index] += 10 * this.mouse.vx * power;
					data[index+1] += 10 * this.mouse.vy * power;
				}
			}
		}

		this.mouse.vx *= 0.9;
		this.mouse.vy *= 0.9;

		this.texture.needsUpdate = true;
	}

	swapImage(image) {
		this.loadedImage = new THREE.TextureLoader().load(image);

		this.material.uniforms.uTexture.value = this.loadedImage;

		let data = this.texture.image.data;

		for (let i = 0; i< this.dataTextureSize; i++) {
			for (let j = 0; j < this.dataTextureSize; j++) {
				let index = 3 * (i + this.dataTextureSize * j);
				//data[index] += Math.random() * .1 * Math.random() * .1;
				data[index+1] += Math.random() * 1.7 * (Math.random() * 2) * Math.random()  * .5;
			}
		}
	}

	mouseEvents() {
		window.addEventListener('mousemove', (e) => {
			this.mouse.x = e.clientX/this.width;
			this.mouse.y = e.clientY/this.height;

			this.mouse.vx = this.mouse.x - this.mouse.prevX
			this.mouse.vy = this.mouse.y - this.mouse.prevY;

			this.mouse.prevX = this.mouse.x;
			this.mouse.prevY = this.mouse.y;
		})

		window.addEventListener('touchmove', (e) => {

			const posX = e.clientX || e.touches[0].clientX;
            const posY = e.clientY || e.touches[0].clientY;

			this.mouse.x = posX/this.width;
			this.mouse.y = posY/this.height;

			this.mouse.vx = this.mouse.x - this.mouse.prevX
			this.mouse.vy = this.mouse.y - this.mouse.prevY;

			this.mouse.prevX = this.mouse.x;
			this.mouse.prevY = this.mouse.y;
		})
	}

	resize() {
		this.width = this.container.offsetWidth;
		this.height = this.container.offsetHeight;

		this.renderer.setSize(this.width, this.height);
		this.camera.aspect = this.width / this.height;
		this.camera.updateProjectionMatrix();

		const viewportAspect = window.innerWidth / window.innerHeight;

		if (this.imageAspect > viewportAspect) {
			this.shaderScale.set(this.imageAspect / viewportAspect, 1);
		} else {
			this.shaderScale.set(1, viewportAspect / this.imageAspect);
		}
	}

	setupResize() {
		window.addEventListener('resize', this.resize);
	}

	render() {
		//this.stats.begin();
			const delta = this.clock.getDelta();
			this.updateDataTexture();
		
			this.scene.traverse(function(obj){
				if (obj.render) obj.render(delta);
			});

            if (this.material) {
                this.material.uniforms.uTime.value += delta;
            }

			this.renderer.render( this.scene, this.camera );

			requestAnimationFrame(this.render.bind(this));
		//this.stats.end();
	}

    createPlane() {
        this.createShaderMaterial()
        const geometry = new THREE.PlaneGeometry(1,1, 20, 20 );
        this.plane = new THREE.Mesh( geometry, this.material );
		this.scene.add( this.plane );
    }

    createShaderMaterial() {
        this.material = new THREE.ShaderMaterial({
			wireframe: false,
            uniforms: {
				uProgress: { value: 0.7 },
				uTexture: { value: this.loadedImage },
                uTime: { value: 0.0 },
				scale: { value: new THREE.Vector2(1, 1) },
				uDataTexture: { value: this.texture }
            },
            vertexShader: this.vertex,
            fragmentShader: this.fragment
        });

		this.shaderScale = this.material.uniforms.scale.value;		
    }
}

export default BackgroundImage;